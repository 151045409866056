import React, { useEffect, useState } from "react";
import { useAuth } from "../auth/AuthContext";

import ItemsTable from "../components/ItemsTable";
import ModalItem from "../components/ModalItem";
import { useNotification } from "../components/NotificationContext";

import { customFetch } from "../utils";

function Beheer() {
    const [data, setData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    const [filters, setFilters] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const auth = useAuth();

    const [modalSaveFunction, setModalSaveFunction] = useState(null);
    const notification = useNotification();

    useEffect(() => {
        fetchDatabase();
    }, []);

    useEffect(() => {
        if (data) {
            updateSearchInput();
        }
    }, [data, searchInput, filters]);

    useEffect(() => {
        if (showModal && selectedItem && selectedItem.id) {
            setSelectedItem(data.find((item) => item.id === selectedItem.id));
        }
    }, [data]);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchDatabase();
        }, 10000); // 10 seconds
        return () => clearInterval(interval);
    }, []);

    const addItem = (item) => {
        //console.log('Add item', item);

        if (item.date != "0") {
            const date = item.date.split("-");
            item.date = date[2] + "-" + date[1] + "-" + date[0];
        } else {
            item.date = null;
        }

        customFetch(
            "/api/addItem",
            "POST",
            { item },
            auth,
            () => {
                fetchDatabase();
                notification("Item added", "success");
            },
            (err) => {
                console.log("Error adding item", err);
                notification("Error adding item", "error");
            }
        );
    };

    const updateDataItem = (item) => {
        const originalItem = data.find((dataItem) => dataItem.id === item.id);

        for (const key in item) {
            if (["name", "order_number", "img", "date", "date_delta", "ua", "meter"].indexOf(key) === -1) {
                continue;
            }
            if (originalItem[key] !== item[key]) {
                console.log("Update", key, item[key]);

                //If date convert 24-04-2024 to 2024-04-24
                if (key === "date") {
                    if (item[key] === "0") {
                        item[key] = null;
                    } else {
                        const date = item[key].split("-");
                        item[key] = date[2] + "-" + date[1] + "-" + date[0];
                    }
                }

                customFetch(
                    "/api/setProp/" + key + "/" + item.id,
                    "POST",
                    { value: item[key] },
                    auth,
                    () => {
                        fetchDatabase();
                        notification("Item updated", "success");
                    },
                    (err) => {
                        console.log("Error updating item", err);
                        notification("Error updating item", "error");
                    }
                );
            }
        }
    };

    const fetchDatabase = () => {
        customFetch(
            "/api/getDatabase",
            "GET",
            null,
            auth,
            (data) => {
                setData(data);
            },
            (err) => {
                console.log("Error fetching database", err);
                notification("Error fetching database", "error");
            }
        );
    };

    const updateSearchInput = () => {
        const input = searchInput.toLowerCase();

        if (input === "" && Object.keys(filters).length === 0) {
            setFilteredData(data);
            return;
        }

        // Split the input into words
        const words = input.split(" ");
        words.sort((a, b) => b.length - a.length);

        const filtered = [];

        for (const item of data) {
            const itemWords = item.name.toLowerCase().split(" ");

            if (filters.meter && item.meter !== filters.meter) {
                continue;
            }

            itemWords.push(item.order_number.toString());
            itemWords.push(item.meter.toString());
            if (item.date) {
                itemWords.push(item.date.toString());
            }

            // Check if all words are present in the item
            let allWordsPresent = true;
            for (const word of words) {
                let find = false;
                for (const itemWord of itemWords) {
                    if (itemWord.includes(word)) {
                        find = true;
                        itemWords.splice(itemWords.indexOf(itemWord), 1);
                        break;
                    }
                }
                if (!find) {
                    allWordsPresent = false;
                    break;
                }
            }
            if (allWordsPresent) {
                filtered.push(item);
            }
        }
        setFilteredData(filtered);
    };

    const updateSearchInputValue = (e) => {
        switch (e.target.name) {
            case "query": {
                setSearchInput(e.target.value);
                break;
            }
            case "meter": {
                if (e.target.value === "0") {
                    setFilters((filters) => {
                        delete filters.meter;
                        return { ...filters };
                    });
                } else {
                    setFilters((filters) => {
                        filters.meter = parseInt(e.target.value);
                        return { ...filters };
                    });
                }
                break;
            }
            default: {
                console.log("Unknown input", e.target.name);
            }
        }
    };

    return (
        <main>
            <section className="mainWrap">
                <aside>
                    <section>
                        <label htmlFor="query"> Search:</label>
                        <input type="text" id="query" name="query" onInput={updateSearchInputValue} />
                        <label htmlFor="meter"> Meter:</label>
                        <select id="meter" name="meter" onChange={updateSearchInputValue}>
                            <option value="0">All</option>
                            {[...Array(17).keys()].map((meter) => {
                                return (
                                    <option key={meter + 1} value={meter + 1}>
                                        {meter + 1}
                                    </option>
                                );
                            })}
                        </select>

                        <button
                            onClick={() => {
                                const item = {
                                    name: searchInput ? "00" : "",
                                    order_number: searchInput ? searchInput : "0",
                                    img: null,
                                    img_path: null,
                                    date: 0,
                                    date_delta: 1,
                                    ua: false,
                                    meter: 1,
                                };


                                setSelectedItem(item);
                                setModalSaveFunction(() => addItem);
                                setShowModal(true);
                            }}
                            className="hhide"
                        >
                            +
                        </button>
                        <p className="hhide">Add Item</p>
                    </section>
                </aside>

                <section>
                    <h1>Beheer</h1>
                    {filteredData && (
                        <ItemsTable
                            items={filteredData}
                            onClick={(item) => {
                                setSelectedItem(item);
                                setModalSaveFunction(() => updateDataItem);
                                setShowModal(true);
                            }}
                        />
                    )}
                </section>

                {showModal && (
                    <ModalItem
                        item={selectedItem}
                        onClick={() => {
                            setShowModal(false);
                        }}
                        updateDataItem={modalSaveFunction}
                        fetchData={fetchDatabase}
                    />
                )}
            </section>
        </main>
    );
}

export default Beheer;
