import { Link } from "react-router-dom";
import { useState } from 'react';
import ModalItem from '../components/ModalItem';

import { useNotification } from '../components/NotificationContext';
import { useAuth } from '../auth/AuthContext';


// Компоненты страниц
function Home() {
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const notification = useNotification();

    const auth = useAuth();

    const addItem = (item) => {
        console.log('Add item', item);

        if (item.date != "0") {
            const date = item.date.split('-');
            item.date = date[2] + '-' + date[1] + '-' + date[0];
        } else {
            item.date = null;
        }


        const request = fetch('/api/addItem', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${auth.user.accessToken}`
            },
            body: JSON.stringify({ item })
        }).then(() => {
            notification('Item toegevoegd', 'success');
        }).catch((err) => {
            console.log('Error adding item', err);
            notification('Error adding item', 'error');
        });

    };


    return (
        <div className="homeWrap">

            <Link to="/daily-list"><div className="linkWrap"> Daily List </div></Link>
            <Link to="/beheer"><div className="linkWrap"> Beheer </div></Link>
            <Link to="/to-good-to-go"><div className="linkWrap"> To Good To Go </div></Link>
            <a onClick={
                () => {
                    const item = {
                        name: '',
                        order_number: '0',
                        img: null,
                        img_path: null,
                        date: 0,
                        date_delta: 1,
                        ua: false,
                        meter: 1
                    };

                    setSelectedItem(item);
                    setShowModal(true);
                }
            }><div className="linkWrap"> Artikel toevoegen </div></a>

            <Link to="/reorder"><div className="linkWrap"> Reorder (beta)</div></Link>

            <Link to="/photos"><div className="linkWrap"> Photos </div></Link>
            <Link to="/items"><div className="linkWrap"> Items </div></Link>
            <Link to="/minder-dan-nul"><div className="linkWrap"> Minder dan nul </div></Link>

            <a onClick={
                () => {
                    const info = window.confirm("Weet je zeker dat je wilt uitloggen?");
                    if (info) {
                        auth.logout();
                    }
                }
            }>
                <div className="linkWrap red"> Uitloggen </div>
            </a>



            {showModal && <ModalItem item={selectedItem} onClick={
                () => {
                    setShowModal(false);
                }
            } updateDataItem={addItem} fetchData={() => { }} />}

        </div>
    );
}


export default Home;