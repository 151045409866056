import React, { useState, useEffect } from 'react';
import { useAuth } from '../auth/AuthContext';

import DailyListTable from '../components/DailyListTable';
import ModalItem from '../components/ModalItem';
import { useNotification } from '../components/NotificationContext';


function MinderDanNul() {
    const [data, setData] = useState([]);
    const [correctOrder, setCorrectOrder] = useState([]);
    const [processedData, setProcessedData] = useState([]);
    const [voorraad, setVoorraad] = useState({ stock: {}, created: null });
    const auth = useAuth();

    const [selectedItem, setSelectedItem] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const notification = useNotification();



    useEffect(() => {
        fetchCorrectOrder();
        fetchDailyList();
        fetchVoorraad();
    }, []);



    useEffect(() => {
        if (!data || !correctOrder || voorraad.created === null) return;
        const processedData = [];
        correctOrder.forEach((meter, i) => {
            processedData.push([]);
            meter.forEach((item) => {
                if (data[item] && voorraad.stock[data[item].order_number] && voorraad.stock[data[item].order_number] < 0) processedData[i].push(data[item]);

                //voorraad.stock[data[item]] ? console.log(voorraad.stock[data[item].order_number]) : console.log("no stock");
            });
        });
        //console.log("processedData", processedData);
        setProcessedData(processedData);
        //console.log("processedData", processedData);
    }, [data, correctOrder, voorraad]);


    const fetchVoorraad = async () => {
        fetch('/api/getVoorraad', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.user.accessToken}`
            }
        }).then((res) => res.json())
            .then((data) => {
                //console.log(data);
                setVoorraad(data);
            }).catch((err) => {
                console.log("Error fetching voorraad", err);
                //fetchVoorraad();
            });

    }




    const fetchCorrectOrder = async () => {
        fetch('/api/getCorrectOrder', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.user.accessToken}`
            }
        }).then((res) => res.json())
            .then((data) => {
                setCorrectOrder(data);
                //console.log("correctOrder", data)
            }).catch((err) => {
                console.log("Error fetching correct order", err);
                //fetchCorrectOrder();
            });
    }

    const fetchDailyList = () => {
        fetch('/api/getDatabase', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.user.accessToken}`
            }
        }).then((res) => res.json())
            .then((data) => {

                const fixedData = {};


                data.map((item) => {
                    item.new_date = item.date;
                    return item;
                }).filter((item) => {
                    return item.ua != 1;
                }).forEach((item) => {
                    fixedData[item.id] = item;
                });

                setData(fixedData);
            }).catch((err) => {
                console.log("Error fetching daily list (database)", err);
                //fetchDailyList();
            });
    }


    useEffect(() => {
        const interval = setInterval(() => {
            fetchDailyList();
            fetchCorrectOrder();
            fetchVoorraad();
        }, 10000); // 10 seconds
        return () => clearInterval(interval);
    }, []);


    const openBeheer = (id) => {
        setSelectedItem(data[id]);
        setShowModal(true);
    }

    useEffect(() => {
        if (selectedItem && data[selectedItem.id])
            setSelectedItem(data[selectedItem.id]);
    }, [data]);

    const quickAction = (id, action) => {
        alert('Quick action does not work yet');
    }


    const newDate = (id, date) => {
        //console.log("fetching", [date])
        if (date === '0') {
            date = null;
        } else if (date != '') {
            const dateSplit = date.split('-');
            date = dateSplit[2] + '-' + dateSplit[1] + '-' + dateSplit[0];
        }

        //console.log("fetching", [date])

        fetch('/api/updateDailyListNode/' + id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.user.accessToken}`
            },
            body: JSON.stringify({ date: date })
        }).then(() => {
            fetchDailyList();
        }).catch((err) => {
            console.log("Error updating date", err);
            //newDate(id, date);
        });
    }

    const updateDataItem = (item) => {
        const originalItem = data[item.id];

        const requests = [];

        console.log('Update item', item);
        for (const key in item) {
            if (['name', 'order_number', 'img', 'date', "date_delta", "ua", "meter"].indexOf(key) === -1) {
                continue;
            }
            if (originalItem[key] !== item[key]) {
                //console.log('Update', key, item[key]);

                //If date convert 24-04-2024 to 2024-04-24
                if (key === 'date') {
                    if (item[key] === "0") {
                        item[key] = null;
                    } else {
                        const date = item[key].split('-');
                        item[key] = date[2] + '-' + date[1] + '-' + date[0];
                    }
                }

                const request = fetch('/api/setProp/' + key + "/" + item.id, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.user.accessToken}`
                    },
                    body: JSON.stringify({ value: item[key] })
                });
                requests.push(request);
            }
        }
        Promise.all(requests).then(() => {
            fetchDailyList();
            fetchCorrectOrder();
        }).then(() => {
            notification("Item updated", "success");
        }).catch((err) => {
            console.log("Error updating item", err);
            notification("Error updating item", "error");
        });
    };


    useEffect(() => {
        const tmp = setTimeout(() => {
            if (document.getElementById('apply')) {
                document.getElementById('apply').style.display = 'none';
            }
        }, 1000);
        return () => clearTimeout(tmp);
    }, []);


    return (
        <div>
            <ul>
                {
                    processedData && <DailyListTable meters={processedData} openBeheer={openBeheer} quickAction={quickAction} newDate={newDate} voorraad={voorraad["stock"]} />
                }




            </ul>


            {showModal && <ModalItem item={selectedItem} onClick={
                () => {
                    setShowModal(false);
                }
            } updateDataItem={updateDataItem} fetchData={fetchDailyList} />}



        </div>
    );
}

export default MinderDanNul;