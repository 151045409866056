import React, { useState, useEffect } from 'react';
import { useAuth } from '../auth/AuthContext';

import DailyListTable from '../components/DailyListTable';
import ModalItem from '../components/ModalItem';
import { useNotification } from '../components/NotificationContext';


function DailyList() {
    const [data, setData] = useState({});
    const [correctOrder, setCorrectOrder] = useState([]);
    const [processedData, setProcessedData] = useState([]);
    const [voorraad, setVoorraad] = useState({ stock: {}, created: null });
    const auth = useAuth();

    const [selectedItem, setSelectedItem] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const notification = useNotification();


    useEffect(() => {
        fetchCorrectOrder();
        fetchDailyList();
        fetchVoorraad();


        fetchDailyListMetaData().then((metaData) => {
            const created = metaData.created; //2024-04-19T07:37:47.469Z
            const date = new Date(created);
            const day = date.getDate();
            const dayNow = new Date().getDate();

            if (day !== dayNow) {
                createNewDailyList().then(() => {
                    fetchCorrectOrder();
                    fetchDailyList();
                });
            } else {
                fetchCorrectOrder();
                fetchDailyList();
            }
        }).catch((err) => {
            console.log("Error fetching daily list metadata", err);
            //fetchDailyListMetaData();
        });
    }, []);



    useEffect(() => {
        if (!data || !correctOrder) return;
        const processedData = [];
        correctOrder.forEach((meter, i) => {
            processedData.push([]);
            meter.forEach((item) => {
                if (data[item]) processedData[i].push(data[item]);
            });
        });
        //console.log("processedData", processedData);
        setProcessedData(processedData);
        //console.log("processedData", processedData);
    }, [data, correctOrder]);


    const createNewDailyList = async () => {
        return fetch('/api/createDailyList', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${auth.user.accessToken}`
            }
        }).then(() => {
            console.log("New daily list created");
        }).catch((err) => {
            console.log("Error creating new daily list", err);
            //return createNewDailyList();
        });

    }



    const fetchCorrectOrder = async () => {
        fetch('/api/getCorrectOrder', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.user.accessToken}`
            }
        }).then((res) => res.json())
            .then((data) => {
                setCorrectOrder(data);
                //console.log("correctOrder", data)
            }).catch((err) => {
                console.log("Error fetching correct order", err);
                //fetchCorrectOrder();
            });
    }

    const fetchDailyList = async () => {
        fetch('/api/getDailyList', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.user.accessToken}`
            }
        }).then((res) => res.json())
            .then((data) => {
                const sortedData = {};
                data.forEach(element => {
                    sortedData[element.id] = element;
                });
                setData(sortedData);
            }).catch((err) => {
                console.log("Error fetching daily list", err);
                notification("Error fetching daily list", "error");
                //fetchDailyList();
            });
    }

    const fetchDailyListMetaData = async () => {
        return fetch('/api/getDailyListMetadata', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.user.accessToken}`
            }
        }).then((res) => res.json())
            .then((data) => {
                //console.log("metaData", data)
                return data;
            }).catch((err) => {
                console.log("Error fetching daily list metadata", err);
                //return fetchDailyListMetaData();
            });
    }

    const fetchVoorraad = async () => {
        fetch('/api/getVoorraad', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.user.accessToken}`
            }
        }).then((res) => res.json())
            .then((data) => {
                //console.log(data);
                setVoorraad(data);
            }).catch((err) => {
                console.log("Error fetching voorraad", err);
                //fetchVoorraad();
            });

    }


    useEffect(() => {
        const interval = setInterval(() => {
            fetchDailyList();
            fetchCorrectOrder();
            fetchVoorraad();
        }, 10000); // 10 seconds
        return () => clearInterval(interval);
    }, []);


    const openBeheer = (id) => {
        setSelectedItem(data[id]);
        setShowModal(true);
    }

    useEffect(() => {
        if (selectedItem && data[selectedItem.id])
            setSelectedItem(data[selectedItem.id]);
    }, [data]);

    const quickAction = (id, action) => {
        alert('Quick action does not work yet');
    }


    const newDate = (id, date) => {
        //console.log("fetching", [date])
        if (date === '0') {
            date = null;
        } else if (date != '') {
            const dateSplit = date.split('-');
            date = dateSplit[2] + '-' + dateSplit[1] + '-' + dateSplit[0];
        }

        //console.log("fetching", [date])

        fetch('/api/updateDailyListNode/' + id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.user.accessToken}`
            },
            body: JSON.stringify({ date: date })
        }).then(() => {
            fetchDailyList();
        }).catch((err) => {
            console.log("Error updating date", err);
            //newDate(id, date);
        });
    }

    const updateDataItem = (item) => {
        const originalItem = data[item.id];

        console.log('Update item', item);
        for (const key in item) {
            if (['name', 'order_number', 'img', 'date', "date_delta", "ua", "meter"].indexOf(key) === -1) {
                continue;
            }
            if (originalItem[key] !== item[key]) {
                //console.log('Update', key, item[key]);

                //If date convert 24-04-2024 to 2024-04-24
                if (key === 'date') {
                    if (item[key] === "0") {
                        item[key] = null;
                    } else {
                        const date = item[key].split('-');
                        item[key] = date[2] + '-' + date[1] + '-' + date[0];
                    }
                }

                const request = fetch('/api/setProp/' + key + "/" + item.id, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.user.accessToken}`
                    },
                    body: JSON.stringify({ value: item[key] })
                });

                request.then((resp) => {
                    fetchDailyList();
                    if (resp.status === 200) {
                        notification('Item updated', 'success');
                    } else {
                        notification('Error updating item', 'error');
                    }
                }).catch((err) => {
                    console.log('Error updating item', err);
                });
            }
        }
    };


    const applyDailyList = () => {
        fetch('/api/applyDailyList', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${auth.user.accessToken}`
            }
        }).then(() => {
            //console.log("Daily list applied");
        }).catch((err) => {
            console.log("Error applying daily list", err);
            //applyDailyList();
        });
    }


    useEffect(() => {
        const tmp = setTimeout(() => {
            if (document.getElementById('apply')) {
                document.getElementById('apply').style.display = 'none';
            }
        }, 1000);
        return () => clearTimeout(tmp);
    }, []);


    return (
        <div>
            <ul>
                {
                    processedData && <DailyListTable meters={processedData} openBeheer={openBeheer} quickAction={quickAction} newDate={newDate} voorraad={
                        voorraad["created"] ? (new Date() - new Date(voorraad["created"].replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$2-$1")) > 7200000 ? {} : voorraad["stock"]) : {}
                    } />
                }




            </ul>


            {showModal && <ModalItem item={selectedItem} onClick={
                () => {
                    setShowModal(false);
                }
            } updateDataItem={updateDataItem} fetchData={fetchDailyList} />}



            {auth.isAdmin() && processedData && <div className='divWrap'>
                <button id='apply' onClick={() => { applyDailyList() }} style={
                    {
                        display: 'none'
                    }

                }>Apply</button>
            </div>}


            {
                voorraad.created &&
                <p>
                    Voorraad van {voorraad.created}
                </p>
            }



        </div>
    );
}

export default DailyList;